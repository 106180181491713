.dropdown-toggle, .dropdown-toggle:hover,
.dropdown-menu, .dropdown-menu:hover,
.dropdown-item, .dropdown-menu-item:hover  {
    background-color: white;
    cursor: pointer;
}

button.card:hover  {
    background-color: white;
    cursor: pointer;
}

