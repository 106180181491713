tr.primaryRow th {
    z-index: 99;
    background: rgb(184, 183, 183); /* background: #032b43; */
    color: black;
}

th.sticky {
    z-index: 99;
    background: white;
    position: sticky;
    top: 0; /* Don't forget this, required for the stickiness */
    /* box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4); */
}