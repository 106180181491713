.pagination>li>a, .pagination>li>span { 
    border-radius: 50% !important;
    margin: 0 5px;
}

.btn-circle.btn-sm { 
    width: 30px; 
    height: 30px; 
    padding: 6px 0px; 
    border-radius: 15px; 
    font-size: 8px; 
    text-align: center; 
}

.btn-circle.btn-md { 
    width: 50px; 
    height: 50px; 
    padding: 7px 10px; 
    border-radius: 25px; 
    font-size: 10px; 
    text-align: center; 
} 