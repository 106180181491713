.hover-pointer:hover {
    cursor: pointer;
}

.hover-black-bg:hover {
    background-color: 'black';
}

.dropdown-menu li:hover {
    cursor: pointer;
}

a:not([href]).dropdown-toggle:hover {
    cursor: pointer;
}

path, svg {
    cursor: pointer;
}

.nav-item a:hover {  cursor: pointer }